.toggle-switch {
    position: relative;
    width: 50px;
    display: inline-block;
    text-align: left;
}
.toggle-switch-checkbox {
    display: none;
}
.toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 2px solid #ddd;
    border-radius: 20px;
}
.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 20px;
    padding: 0;
    line-height: 20px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
}
.toggle-switch-inner:before {
    content: attr(data-yes);
    background-color: #34c759;
    color: white;
}
.toggle-switch-inner:after {
    content: attr(data-no);
    background-color: #e5e5e5;
    color: #333;
    text-align: right;
}
.toggle-switch-switch {
    display: block;
    width: 18px;
    margin: 1px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 28px;
    border: 2px solid #ddd;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
}
